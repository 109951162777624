<template>
  <page-scaffold>
    <card-content :is-loading="isLoading" name="Чат с поддержкой" style="overflow-y: hidden"
                  :loading-title="loadingTitle">
      <div class="chat-container">
        <div class="safe-zone" style="grid-area: safe-zone1"></div>
        <div class="chat-message-list" style="grid-area: messages">
          <item-message v-for="message in messages.slice().reverse()"
                        :key="message.id"
                        :text="message.text"
                        :is-me="message.isMe"
                        :time="message.time"
                        :status="message.status"/>
          <div id="fade_line"/>
        </div>
        <div class="safe-zone" style="grid-area: safe-zone1"></div>
        <div id="chat-form" style="grid-area: input">
          <input type="text" autocomplete="off" id="message-field"
                 placeholder="Введите сообщение" v-model="message">
          <a ref="#"><img id="btn-send" :src="require('@/assets/ic_send_message.svg')" @click="sendMessage" alt="send"></a>
        </div>
      </div>
    </card-content>
  </page-scaffold>
</template>

<script>
import PageScaffold from "./page-scaffold";
import CardContent from "./card-content";
import ItemMessage from "./item-message";
import axios from "axios";
import Pusher from "pusher-js"

export default {
  name: "page-chat",
  components: {ItemMessage, CardContent, PageScaffold},
  data: () => {
    return {
      isLoading: false,
      loadingTitle: 'Загрузка...',
      messages: [],
      message: ''
    }
  },
  methods: {
    getNow() {
    },
    sendMessage() {
      if (this.message !== '') {
        let locMess = this.message
        this.message = ''

        // Add message immediately with pending status
        const tempMessage = {
          id: Date.now(), // temporary ID
          text: locMess,
          isMe: true,
          time: Date.now() / 1000,
          status: 'pending'
        }
        this.messages.push(tempMessage)

        axios({
            method: "PUT",
            url: "v1/chat",
            headers: {Authorization: `Bearer ` + localStorage.token},
            params: {
              messageText: locMess
            }
          }).then(response => {
            // Update message status to sent
            const messageIndex = this.messages.findIndex(m => m.id === tempMessage.id)
            if (messageIndex !== -1) {
              this.messages[messageIndex].status = 'sent'
              this.messages[messageIndex].id = response.data.id // Update with real ID if provided
            }
          }).catch(() => {
            // Update message status to error
            const messageIndex = this.messages.findIndex(m => m.id === tempMessage.id)
            if (messageIndex !== -1) {
              this.messages[messageIndex].status = 'error'
            }
          })
      }
    }
  },
  mounted() {
    let self = this

    document.getElementById("message-field")
        .addEventListener("keyup", function (event) {
          if (event.keyCode === 13) {
            event.preventDefault();
            self.sendMessage()
          }
        });

    const pusher = new Pusher('ba59471ae56d94e344e1', {
      cluster: 'eu'
    });

    const channel = pusher.subscribe(localStorage.userID);
    channel.bind('newMessage', function (data) {
      // Add status field to incoming messages
      data.message.status = 'sent'
      self.messages.push(data.message)
    });

    this.isLoading = true
    this.loadingTitle = 'Загрузка...'

    axios({
      method: "GET",
      url: "v1/chat",
      headers: {Authorization: `Bearer ` + localStorage.token},
    }).then(response => {
      // Add status field to all loaded messages
      this.messages = response.data.map(msg => ({
        ...msg,
        status: 'sent'
      }))
      this.isLoading = false
    })
  }
}
</script>

<style scoped>

.safe-zone {

}

.chat-container {
  display: grid;
  grid-template-columns: auto minmax(auto, 900px) auto;
  padding-bottom: 24px;
  width: calc(100%);
  height: calc(100% - 24px);
  grid-template-rows: auto max-content;
  grid-template-areas:
    "safe-zone1 messages safe-zone2"
    "safe-zone1 input safe-zone2";

}

.chat-message-list {
  transition: all 0.3s ease;
  display: flex;
  padding: 0 20px;
  margin-top: 8px;
  overflow-y: scroll;
  flex-direction: column-reverse;
}

#chat-form {
  display: grid;
  grid-template-columns: 1fr 52px;
  align-content: center;
  align-items: center;
  grid-area: chat-form;
  padding-left: 16px;
}

#chat-form a img {
  margin-left: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

#chat-form input {
  font-family: "Mont", serif;
  font-weight: bold;
  outline: none;
  padding: 8px 24px;
  border: 0;
  color: #404040;
  min-height: 24px;
  height: auto;
  border-radius: 16px;
  font-size: 1.1rem;
  background: #f5f5f5;
  max-lines: 2;
}

</style>